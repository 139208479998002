import { defineStore } from "pinia"

const useStepStore = defineStore('step', {
  state: () => ({
    mainSteps: {
      id: 0,
      list: [{ id: 0, name: '1' }]
    },
    supportSteps: {
      id: 0,
      list: [{ id: 0, name: '1' }]
    }
  }),
  getters: {
    getMainStepsInfo: (state) => {
      const { id, list } = state.mainSteps

      return {
        isFirstStep: id === list[0].id,
        isLastStep: id === list[list.length - 1].id,
        isOutOfBounds: id < list[0].id || id > list[list.length - 1].id
      }
    },
    getSupportStepsInfo: (state) => {
      const { id, list } = state.supportSteps

      return {
        isFirstStep: id === list[0].id,
        isLastStep: id === list[list.length - 1].id,
        isOutOfBounds: id < list[0].id || id > list[list.length - 1].id
      }
    }
  },
  actions: {
    setMainStepId(payload = 0) {
      this.mainSteps = { ...this.mainSteps, id: payload }
    },
    setMainStepList(payload = [{ id: 0, name: '1' }]) {
      this.mainSteps = { ...this.mainSteps, list: [...payload] }
    },
    setSupportStepId(payload = 0) {
      this.supportSteps = { ...this.supportSteps, id: payload }
    },
    setSupportStepList(payload = [{ id: 0, name: '1' }]) {
      this.supportSteps = { ...this.supportSteps, list: [...payload] }
    }
  }
})

export { useStepStore }